<template>
  <div>
    <el-form label-width="60px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="学校:">
            <el-select v-model="form.school_id"
                       filterable
                       style="width:100%"
                       @clear="getClassList"
                       placeholder="选择学校">
              <el-option v-for="item in schoolList"
                         :key="item.school_id"
                         :label="item.school_name"
                         :value="item.school_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="年级:">
            <el-select v-model="form.grade_id"
                       style="width:100%"
                       clearable
                       @change="getClassList"
                       placeholder="选择年级">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="班级:">
            <el-select v-model="form.class_id"
                       style="width:100%"
                       clearable
                       placeholder="选择班级">
              <el-option v-for="item in classList"
                         :key="item.id"
                         :label="item.class_name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       v-model="subject_id"
                       @change="initData"
                       placeholder="选择科目">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="15">
          <el-button type="primary"
                     @click="initData">查询</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table border
              stripe
              highlight-current-row
              v-if="info.user_weak"
              :max-height="$store.state.tableHeight + 100"
              :data="info.user_weak"
              style="width: 100%">
      <el-table-column prop="check_point_id"
                       align="center"
                       width="150"
                       label="知识点ID" />
      <el-table-column prop="check_point_desc"
                       align="center"
                       label="知识点名称" />
      <el-table-column prop="question_times"
                       align="center"
                       label="学生做题次数" />
      <el-table-column prop="score_rate"
                       align="center"
                       label="学生得分率" />
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: {},
      subjectList: [],
      schoolList: [],
      gradeList: [],
      classList: [],
      form: {
        school_id: '',
        grade_id: '',
        class_id: '',
        subject_id: ''
      },
      subject_id: '',
    }
  },
  created () {
    this.getSubjectList()
    this.getSchoolList()
    this.getGradeList()
  },
  methods: {
    getSchoolList () {
      this.$http({
        url: '/api/v1/school/get_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get',
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    getClassList () {
      this.classList = []
      this.form.class_id = ''
      if (this.form.grade_id == '' || this.form.grade_id == '') {
        return
      }
      this.$http({
        url: '/api/v1/special/get_special_class',
        method: 'get',
        params: {
          grade_id: this.form.grade_id,
          school_id: this.form.school_id
        }
      }).then(res => {
        this.classList = res.data.list
      })
    },
    initData () {
      this.$http({
        url: '/api/v1/weak/student_detail',
        method: 'post',
        data: form
      }).then(res => {
        this.info = res.data.list.data
      })
    },
    getSubjectList () {
      this.$http({
        url: '/api/v1/school/get_subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
